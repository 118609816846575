









import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import Velocity from "velocity-animate";

@Component
export default class OverlayFadeTransition extends Vue {
  @Prop({ default: 300, validator: (value: number) => value >= 0 })
  duration!: number;

  beforeEnter(el: HTMLElement): void {
    el.style.opacity = "0";
  }

  enter(el: HTMLElement, done: () => void, duration: number): void {
    Velocity(
      el,
      {
        opacity: 1
      },
      {
        duration,
        complete: done
      }
    );
  }

  leave(el: HTMLElement, done: () => void, duration: number): void {
    Velocity(
      el,
      {
        opacity: 0
      },
      {
        duration,
        complete: done
      }
    );
  }
}
